export const kPageContentInfo = {
    base: [
        {
            type: 'img_list',
            children: [
                "https://guinong-dev-1305192904.cos.ap-shanghai.myqcloud.com/wanfei-offical/base/1.jpg",
                "https://guinong-dev-1305192904.cos.ap-shanghai.myqcloud.com/wanfei-offical/base/4.jpg",
                "https://guinong-dev-1305192904.cos.ap-shanghai.myqcloud.com/wanfei-offical/base/5.jpg",
                "https://guinong-dev-1305192904.cos.ap-shanghai.myqcloud.com/wanfei-offical/base/6.jpg",
                "https://guinong-dev-1305192904.cos.ap-shanghai.myqcloud.com/wanfei-offical/base/7.jpg",
                "https://guinong-dev-1305192904.cos.ap-shanghai.myqcloud.com/wanfei-offical/base/8.jpg",
                "https://guinong-dev-1305192904.cos.ap-shanghai.myqcloud.com/wanfei-offical/base/9.jpg",
                "https://guinong-dev-1305192904.cos.ap-shanghai.myqcloud.com/wanfei-offical/base/10.jpg",
                "https://guinong-dev-1305192904.cos.ap-shanghai.myqcloud.com/wanfei-offical/base/11.jpg",
                "https://guinong-dev-1305192904.cos.ap-shanghai.myqcloud.com/wanfei-offical/base/12.jpg",
            ],
        },
        {
            type: 'p',
            children: [
                `享誉“亚洲第一、世界第三”的云南元江库拉索芦荟基地。种植面积近10000亩，年产8万吨鲜叶，通过了欧盟有机认证。这是全球权威、公认的有机认证，认证标准非常严。芦荟种植不用农药、不用除草剂人工除草、不用化肥、只用有机肥！`,
            ],
        },
        {
            type: 'img_list',
            children: [
                "https://guinong-dev-1305192904.cos.ap-shanghai.myqcloud.com/wanfei-offical/base/13.jpg",
                "https://guinong-dev-1305192904.cos.ap-shanghai.myqcloud.com/wanfei-offical/base/14.jpg",
                "https://guinong-dev-1305192904.cos.ap-shanghai.myqcloud.com/wanfei-offical/base/15.jpg",
                "https://guinong-dev-1305192904.cos.ap-shanghai.myqcloud.com/wanfei-offical/base/16.jpg",
                "https://guinong-dev-1305192904.cos.ap-shanghai.myqcloud.com/wanfei-offical/base/17.jpg",
                "https://guinong-dev-1305192904.cos.ap-shanghai.myqcloud.com/wanfei-offical/base/18.jpg",
                "https://guinong-dev-1305192904.cos.ap-shanghai.myqcloud.com/wanfei-offical/base/19.jpg",
            ],
        },
    ],
}
